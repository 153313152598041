import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';

import { AuthStore } from 'hooks/useAuth';

export default new ApolloClient({
  fetchOptions: {
      mode: 'no-cors'
  },
  link: setContext((_, { headers }) => ({
    headers: {
      ...headers,
      appsecret: '986d37213d241b5b221a469c1242477a',
      'apollographql-client-name': 'ControlCenter',
      'apollographql-client-version': process.env.REACT_APP_VERSION,
    },
  })).concat(createHttpLink({ uri: process.env.REACT_APP_SANDBOXVR_GRAPHQL })),
  cache: new InMemoryCache(),
});
