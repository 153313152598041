import React from 'react';
import axios from 'axios';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import { Query } from 'react-apollo';
import { Grid, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { useParams } from 'react-router-dom';
import { flow } from 'lodash';

import { useStoreInfo, withStoreInfo } from 'hooks/useStoreInfo';
import useAuth from 'hooks/useAuth';

import Queue from './Queue';
import Spaces from './Spaces';
import TeamDetailDialog from './TeamDetailDialog';
import TeamDetailContext from './teamDetailContext';
import useTeamDetail from '../hooks/teamDetail';
import eventStatusCode from '../config/code.json';
import { isQueueStatus, clearBarrackDevicesByEventId } from '../helper/helper';

// fetch data from mock data
// import MockEventsData from '../mocks/event.json';

const UPDATE_DEVICE_URL = `${process.env.REACT_APP_SANDBOXVR_TV_BASE_URL}/barrack/showEvent`;

// Styling
const useStyles = makeStyles(theme => ({
  column: {
    position: 'relative',
    padding: theme.spacing(0.5),
    height: '100vh',
    overflow: 'auto',
  },
  frame: {
    position: 'absolute',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: grey[500],
    borderRadius: 4,
    color: grey[500],
    left: theme.spacing(0.25),
    right: theme.spacing(0.25),
    top: theme.spacing(0.25),
    bottom: theme.spacing(0.25),
    pointerEvents: 'none',
    '& + div': {
      paddingTop: theme.spacing(1.5),
    },
  },
  panel: {
    backgroundColor: 'black',
    color: 'white',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    borderBottomWidth: theme.spacing(0.5),
    borderBottomStyle: 'solid',
    borderBottomColor: 'red',
    display: 'flex',
    alignItems: 'center',
  },
  queueIcon: {
    width: '1.5em',
    height: '1.5em',
  },
  board: {
    padding: '1px',
  },
  middleBoard: {
    padding: '1px 0px 1px 0px',
  },
}));

const UPDATE_EVENT_TO = gql`
  mutation sessionData($id: String!, $assignedBarrack: Int!, $assignedRoom: Int!) {
    sessionData(id: $id, assignedBarrack: $assignedBarrack, assignedRoom: $assignedRoom) {
      id
      assignedBarrack
      assignedRoom
    }
  }
`;

const UPDATE_EVENT_TO_ROOM = gql`
  mutation sessionData($id: String!, $assignedRoom: Int!) {
    sessionData(id: $id, assignedRoom: $assignedRoom) {
      id
      assignedRoom
    }
  }
`;

const GET_SOMETHING = gql`
  query Events($startDateFrom: String!, $startDateTo: String!, $locations: [String]!) {
    events(startDateFrom: $startDateFrom, startDateTo: $startDateTo, locations: $locations) {
      id
      assignedRoom
      assignedBarrack
      purchasedRoom
      status
      startDate
      endDate
      checkfrontBookingId
      members {
        id
        firstName
        lastName
      }
      checkedIn {
        guests {
          id
          firstName
          lastName
          email
        }
        minors {
          name
          id
          name
        }
      }
      host {
        id
        email
        firstName
        lastName
      }
      experience {
        id
        title
        language
      }
    }
  }
`;

const Frame = props => {
  const classes = useStyles();
  const { text } = props;
  return [
    <fieldset key="field" className={classes.frame}>
      <legend>{text}</legend>
    </fieldset>,
    <div key="padding" />,
  ];
};

const Panel = props => {
  // fetch data from mock data
  // const data = MockEventsData;
  // fetch real data via graphql

  // get style
  const classes = useStyles();

  // get token
  const { token } = useAuth();

  // get all data
  const { data, meta, client, location, devices } = props;

  // data filter all terminated event
  const eventsCheckedIn = data
    .filter(event => {
      return event.checkedIn.guests.length > 0 || event.checkedIn.minors.length > 0;
    })
    .map(event =>
      // checkfront do not check the null value
      // as well as the checkfront webhook
      ({
        ...event,
        host: {
          ...event.host,
          firstName: event.host.firstName === null ? '' : event.host.firstName,
          lastName: event.host.lastName === null ? '' : event.host.lastName,
        },
      }),
    );

  const barracks = meta.rooms.map(room => ({
    id: room.roomNumber,
    name: `barrack ${room.roomNumber}`,
  }));

  const rooms = meta.rooms.map(room => ({
    id: room.roomNumber,
    name: `room ${room.roomNumber}`,
  }));

  const categoriedEvents = {
    // only get checked-in events for queue
    queue: eventsCheckedIn.filter(isQueueStatus),
    barracks: [
      ...eventsCheckedIn.filter(
        event =>
          event.assignedRoom === eventStatusCode.UNASSIGNED ||
          (event.assignedRoom === null && event.assignedBarrack !== null),
      ),
    ],
    rooms: eventsCheckedIn.filter(
      event => event.assignedRoom === eventStatusCode.UNASSIGNED || event.assignedRoom !== null,
    ),
  };
  const availableBarracks = barracks
    .map(barrack => barrack.id)
    .filter(
      barrack =>
        !categoriedEvents.barracks
          .map(event => event.assignedBarrack)
          .filter(value => value !== null)
          .includes(barrack),
    );
  const availableRooms = rooms
    .map(room => room.id)
    .filter(
      room =>
        !categoriedEvents.rooms
          .map(event => event.assignedRoom)
          .filter(value => value !== null)
          .includes(room),
    );

  const assignTeam = async (item, spaceId, spaceType) => {
    const roomDevices = devices.filter(
      // developerDevice will only show in dev devices
      ele =>
        ele.config.deviceMode === spaceType &&
        ele.config.roomNumber === String(spaceId) &&
        !ele.config.developerDevice,
    );

    const reducer = sType => {
      const action = {
        variables: { id: item.id },
        query: '',
        afterHook: [],
      };
      switch (sType) {
        case 'queue':
          return {
            ...action,
            query: UPDATE_EVENT_TO,
            variables: {
              ...action.variables,
              assignedBarrack: eventStatusCode.UNASSIGNED,
              assignedRoom: eventStatusCode.UNASSIGNED,
            },
          };
        case 'barrack':
          return {
            ...action,
            query: UPDATE_EVENT_TO,
            variables: {
              ...action.variables,
              assignedBarrack: spaceId,
              assignedRoom: eventStatusCode.UNASSIGNED,
            },
            afterHook: [
              () =>
                clearBarrackDevicesByEventId({
                  location,
                  token,
                  eventId: item.id,
                }),
              () =>
                Promise.all(
                  roomDevices.map(d =>
                    axios.post(
                      UPDATE_DEVICE_URL,
                      {
                        deviceId: d.config.serial,
                        eventUUID: item.id,
                      },
                      {
                        headers: {},
                      },
                    ),
                  ),
                ),
            ],
          };
        case 'room':
          return {
            ...action,
            query: UPDATE_EVENT_TO_ROOM,
            variables: {
              ...action.variables,
              assignedRoom: spaceId,
            },
            afterHook: [
              () =>
                Promise.all(
                  roomDevices.map(d =>
                    axios.post(
                      UPDATE_DEVICE_URL,
                      {
                        deviceId: d.config.serial,
                        eventUUID: item.id,
                      },
                      {
                        headers: {},
                      },
                    ),
                  ),
                ),
            ],
          };
        default:
          return console.warn('spaceType do not support', item, spaceId, spaceType);
      }
    };

    const { query, variables, afterHook } = reducer(spaceType);
    try {
      await client.mutate({
        variables,
        mutation: query,
      });
      for (const fn of afterHook) {
        await fn();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const [teamDetail, setTeamDetail, clearTeamDetail] = useTeamDetail();

  return [
    <TeamDetailContext.Provider key="panel" value={{ onClick: setTeamDetail }}>
      <Grid container>
        <Grid item xs={4} className={classes.column}>
          <Frame text="Queue" />
          <Queue events={categoriedEvents.queue} />
        </Grid>
        <Grid item xs={4} className={classes.column}>
          <Frame text="Barrack" />
          <Spaces
            type="BARRACK"
            data={barracks}
            events={categoriedEvents.barracks}
            eventField="assignedBarrack"
            onDrop={(...args) => assignTeam(...args, 'barrack')}
          />
        </Grid>
        <Grid item xs={4} className={classes.column}>
          <Frame text="Room" />
          <Spaces
            type="ROOM"
            data={rooms}
            events={categoriedEvents.rooms}
            eventField="assignedRoom"
            onDrop={(...args) => assignTeam(...args, 'room')}
          />
        </Grid>
      </Grid>
    </TeamDetailContext.Provider>,
    <TeamDetailDialog
      client={client}
      key="team-detail-dialog"
      detail={teamDetail}
      availableBarracks={availableBarracks}
      availableRooms={availableRooms}
      onClose={clearTeamDetail}
      assignTeam={assignTeam}
      events={data}
    />,
  ];
};

// browser local time
// startDateFrom: day start
// startDateTo: day end
const QueriedPanel = ({ client, storeInfo }) => {
  const { location } = useParams();
  const { store, devices } = storeInfo;
  return (
    <Query
      query={GET_SOMETHING}
      pollInterval={5000}
      variables={{
        locations: [location],
        startDateFrom:
          process.env.NODE_ENV === 'development'
            ? String(DateTime.local().startOf('year').ts)
            : String(DateTime.local().startOf('day').ts),
        startDateTo:
          process.env.NODE_ENV === 'development'
            ? String(DateTime.local().endOf('year').ts)
            : String(DateTime.local().endOf('day').ts),
      }}
    >
      {({ loading, error, data, variables }) => {
        if (loading) return 'Loading...';
        if (error) return `Error! ${error.message}`;
        return (
          <Panel
            data={data.events.filter(event => event.status === 'reserved')}
            devices={devices}
            client={client}
            meta={store}
            location={location}
          />
        );
      }}
    </Query>
  );
};

export default flow([withStoreInfo])(QueriedPanel);
