import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import TeamDetailContext from './teamDetailContext';
import { timestampToTime as time, timestampToDate as date } from '../time';

const IMAGES = {
  amber:
    'https://res.cloudinary.com/sandboxvrcdn/image/upload/v1570098149/web/images/amber_zk2qtk.png',
  curse:
    'https://res.cloudinary.com/sandboxvrcdn/image/upload/v1570098149/web/images/curse_kvpfmr.png',
  deadwood:
    'https://res.cloudinary.com/sandboxvrcdn/image/upload/v1570098149/web/images/deadwood_ekeiq4.png',
  startrek:
    'https://res.cloudinary.com/sandboxvrcdn/image/upload/v1570098149/web/images/startrek_ast5mv.png',
  ufl: 'https://res.cloudinary.com/sandboxvrcdn/image/upload/v1570098149/web/images/ufl_iplpko.png',
};

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(1),
    marginBottom: 0,
    cursor: 'grab',
    textAlign: 'left',
  },
  title: {
    fontWeight: 'bold',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  value: {
    fontWeight: 'normal',
  },
}));

const Team = props => {
  const classes = useStyles();
  const { onClick } = useContext(TeamDetailContext);
  const { event } = props;
  const {
    id,
    purchasedRoom,
    startDate,
    endDate,
    checkfrontBookingId,
    host,
    checkedIn,
    experience,
  } = event;

  let members = [];
  if (checkedIn) {
    members = [
      ...members,
      ...checkedIn.guests,
      ...checkedIn.minors.map(minor => {
        return {
          __typename: minor.__typename,
          id: minor.id,
          firstName: minor.name,
          lastName: '',
        };
      }),
    ];
  }

  const handleClick = () => {
    onClick(event);
  };

  const StyledCardHeader = withStyles(theme => ({
    root: {
      minHeight: theme.spacing(10),
      textShadow: `2px 2px 6px ${red[500]}`,
      alignItems: 'flex-start',
    },
    content: {
      color: 'white',
    },
    subheader: {
      color: 'white',
    },
  }))(CardHeader);

  return (
    <Card className={classes.card} onClick={handleClick}>
      <StyledCardHeader
        className={classes.title}
        title={checkfrontBookingId}
        subheader={`${time(startDate)}`}
        style={{ backgroundImage: `url(${IMAGES[experience.id]})` }}
      />
      <CardContent>
        <Typography className={classes.title} color="textSecondary">
          {`Reserved Room: ${purchasedRoom}`}
        </Typography>
        <Typography className={classes.title} color="textSecondary">
          {`Name: ${host.firstName} ${host.lastName}`}
        </Typography>
      </CardContent>
    </Card>
  );
};

Team.propTypes = {
  event: PropTypes.object.isRequired,
};

export default Team;
